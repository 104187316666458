import React, {useEffect, useState} from 'react';
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

import StrapiAPI from "../../data/strapi";
import VideoThumb from '../videoThumb/videoThumb'

const FILTER_TOP = 'top'
const FILTER_NEW = 'new'

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    if (isFirstItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ChevronLeftIcon className='text-white'/>
        </div>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

    if (isLastItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ChevronRightIcon className='text-white'/>
        </div>
    );
}

function VideoList(props) {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);

    const {
        gameId,
        exclude = false,
        filter = FILTER_NEW, // default filter
        type = false,
        videos: hasVideos
    } = props;

    useEffect(() => {
        if (hasVideos) {
            setVideos(hasVideos)
            setLoading(false)
        } else {
            setLoading(true)
            if (filter === FILTER_TOP) {
                StrapiAPI.getGameVideosTop(gameId)
                    .then((response) => {
                        setVideos(response.data);
                        setLoading(false)
                    })
            } else if (filter === FILTER_NEW) {
                StrapiAPI.getGameVideosNew(gameId)
                    .then((response) => {
                        setVideos(response.data);
                        setLoading(false)
                    })
            }
        }
    }, [filter, gameId])

    if (loading && 1 == 2) {
        return (
            <div className='flex flex-row py-2 overflow-hidden h-58 items-center '>
                <div className='flex flex-row space-x-1.5 mt-1 flex-nowrap overflow-x-scroll'>
                    <div className='text-center w-screen animate-pulse'>loading...</div>
                </div>
            </div>
        );
    }

    if (videos.length === 0) {
        return (
            <div className='flex flex-col py-2 overflow-hidden h-58 items-center '>
                <div className='flex flex-col space-x-1.5 mt-1 flex-nowrap overflow-x-scroll'>
                    <div className='text-center w-screen align-middle'>No videos found</div>
                </div>
            </div>
        );
    }

    return (
        <ScrollMenu
            wrapperClassName='mt-1 py-2 h-58 relative'
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
            {videos.map((video, index) => {
                const {attributes, attributes: {video_id}} = video
                if (exclude && exclude === video_id) {
                    return null
                }

                if (type && type !== attributes.type) {
                    return null
                }

                return (
                    <VideoThumb key={`${video_id}${index}`} {...attributes} index={index}/>
                )
            })
            }
        </ScrollMenu>
    );
}

export default VideoList;

