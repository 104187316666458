import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Link from "next/link";

import VideoList from '../videoList/videoList'
import GameList from '../gameList/gameList'

import {slugifyGame} from "../../utils/slug";

const CategoryGames = (props) => {
    const [selectedGame, setSelectedGame] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('new')
    const {items, type} = props

    // 1 or more video
    const cleanedGamesList = items.filter(g => (g.video_count || g.video_count !== 0))

    useEffect(() => {
        setSelectedGame(cleanedGamesList[0])
    }, [])

    if (cleanedGamesList.length === 0) {
        return null
    }

    return (
        <React.Fragment>
            {cleanedGamesList && <GameList type={type} gameList={cleanedGamesList} selectedGame={selectedGame.id} gameChange={(game) => {
                setSelectedGame(game)
                setSelectedFilter('new')
            }}/>}
            <div className='flex flex-row justify-between sm:flex-row-reverse text-right -mb-1 mt-2 ml-2'>
                <div className='btn-group'>
                    <button className={`btn btn-xs text-s ${selectedFilter === 'new' ? 'btn-active' : ''}`} onClick={() => setSelectedFilter('new')}>Recent</button>
                    <button className={`btn btn-xs text-s ${selectedFilter === 'top' ? 'btn-active' : ''}`} onClick={() => setSelectedFilter('top')}>Top</button>
                </div>
                <div className='btn btn-xs'><Link href={`/games/${slugifyGame(selectedGame.id, selectedGame.name)}`} >Game Page</Link></div>
            </div>
            {selectedGame && <VideoList gameId={selectedGame.id} filter={selectedFilter} />}
        </React.Fragment>
    )
}

CategoryGames.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};

export default CategoryGames

