import React from 'react'

function Module(props) {
    const {title} = props

    return (
        <div className='flex flex-col h-auto mx-1 mb-2 mt-3 relative py-1'>
            <h2 className='text-xl pl-2 mt-3 mb-0.5 font-bold'>{title}</h2>
            {props.children}
        </div>
    )
}

export default Module

