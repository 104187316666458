import React from 'react'
import PropTypes from 'prop-types'
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

import GameThumb from '../gameThumb/gameThumb'

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    if (isFirstItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ChevronLeftIcon className='text-white'/>
        </div>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

    if (isLastItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ChevronRightIcon className='text-white'/>
        </div>
    );
}

function GameList(props) {
    const {gameList, gameChange, type, selectedGame} = props

    return (
        <ScrollMenu
            wrapperClassName='mt-2 pt-0.5 pb-3 h-auto relative border-b-1'
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
            {gameList.map((game, index) => {
                const {id} = game
                return (<GameThumb selectedGame={selectedGame} type={type} key={id + index} index={index}
                                   gameChange={gameChange} game={game}/>)
            })
            }
        </ScrollMenu>
    )
}

GameList.propTypes = {
    gameList: PropTypes.array.isRequired,
    gameChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}

export default GameList

