import React from 'react'
import Image from 'next/future/image'
import PropTypes from 'prop-types'

function GameThumb(props) {
    const {game, gameChange, index, type, selectedGame} = props

    const {imgSquare = false, name, thumbnail, backers = ''} = game

    const isCF = (type === 'bgg_crowdfunding')
    const isHOT = (type === 'bgg_hotness')

    const iActive = game.id === selectedGame

    return (
        <div className={`${iActive ? 'active' : ''} w-32 mx-2 transition-all duration-75 cursor-pointer ${isCF ? 'h-38': 'h-36'} ${index === 0 ? 'ml-2' : ''} `} onClick={() => (gameChange(game))}>
            {isHOT && <div className='text-xxxl h-1 mt-0.5 hot-index'>{index+1}</div>}
            <div className='overflow-hidden w-32 h-30'>
                <Image
                    src={imgSquare ? imgSquare : thumbnail}
                    alt={name}
                    loading='lazy'
                    width={128}
                    height={120}
                />
            </div>
            <h5 className='text-xs truncate overflow-hidden mt-1 h-4'>{name}</h5>
            {isCF && <div className='text-xxs h-1 mt-0.5'>{backers} Backers</div>}
        </div>
    )
}

GameThumb.propTypes = {
    game: PropTypes.object.isRequired,
    gameChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}

export default GameThumb
